const validateIfHaveTokenCookies:any = async () => {
  const token = useCookie('token')
  return token.value
}

// ---cut---
export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = await validateIfHaveTokenCookies()
  if ( !token ) {
    return navigateTo('/')
  }
})
